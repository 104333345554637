import Vue from 'vue'
import VueRouter from 'vue-router'
const index = () => import('@/views/index')
const list = () => import('@/views/list')
const details = () => import('@/views/details')
const search = () => import('@/views/search')
const recommendList = () => import('@/views/recommend')
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
  routes: [
    { path: '/', component: index,name: "index", },
    { path: '/list', component: list, name: "list", },
    { path: '/details', component: details, name: "details", },
    { path: '/search', component: search, name: "search", },
    { path: '/recommendList', component: recommendList, name: "recommendList", },
  ]
})
export default router