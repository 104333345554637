import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router'
import { Search,NavBar,PasswordInput, NumberKeyboard,Button,List,Cell,Image as VanImage,Icon,Toast,Empty} from 'vant';
import 'vant/lib/index.css';
import 'amfe-flexible'
import VueClipboard from 'vue-clipboard2'

axios.defaults.baseURL = 'http://api.tokyohrq.com'
axios.defaults.timeout = 2000

Vue.prototype.$axios = axios
Vue.config.productionTip = false

Vue.use(Search);
Vue.use(NavBar);
Vue.use(PasswordInput);
Vue.use(NumberKeyboard);
Vue.use(Button);
Vue.use(List);
Vue.use(Cell);
Vue.use(VanImage);
Vue.use(Icon);
Vue.use(Toast);
Vue.use(Empty);
Vue.use(VueClipboard)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
